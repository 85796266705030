import React from 'react';
import Layout from '../components/layout';
import Happiness from '../components/happiness';

class HappinessPage extends React.Component {

  render() {
    return (
      <Layout>
        <Happiness />
      </Layout>
    )
  }
}

export default HappinessPage;
