import React from 'react';
import styled from 'styled-components';
import { Container, Row, Col, Image } from 'react-bootstrap';

const Heading = styled.div`
  margin: 30px 0;
  text-align: center;
`;

const Title = styled.p`
  font-weight: bold;
  font-size: larger;
  color: #000000;
`;

const Content = styled.div`
  text-align: justify;
`;

const StyledHappinessCol = styled(Col)`
    text-align: center;
`;

const StyledImage = styled(Image)`
  display: inline !important;
  max-width: 75%;
  margin-top: 10px;
`;

const StyledSpan = styled.span`
    font-size: small;
    width: 100%;
    display: block;
    text-align: center;
    border: 2px solid black;
    margin-top: 10px;
    padding: 5px;
`;

const StyledCol = styled(Col)`
  padding: 15px;
  border-top: 1px solid #f7941e;
`;

const ServiceTitle = styled.p`
  font-weight: bold;
  color: #000000;
`;

const ServiceContent = styled.div`
  margin-top: 15px;
  text-align: justify;
`;

const Happiness = () => (
    <Container fluid="true">
      <Heading>
        <Title>The Happiness Program</Title>
      </Heading>
      <Content>
        <p>The Happiness Program is a program designed for those super talented men
        and women who are required to multi-task like a parent of 4, are expected to
        be as disciplined as a soldier on duty, and have to lift the heavy weight of
        boss’s expectations like a weightlifter on steroids.</p>
        <p>&nbsp;</p>
        <p>Basically everyone at work.</p>
      </Content>
      <Heading>
        <Title>Why is it Important?</Title>
      </Heading>
      <Content>
        <p>We are not yet sure how much of your revenue is getting effected by the stress
        in your organization, but we do know the following*:</p>
      </Content>
      <p>&nbsp;</p>
      <Row>
        <StyledHappinessCol xs={{span: 6, offset:3}} md={{span: 2, offset:1}}>
          <StyledImage className="img-responsive" src="/images/happy1.png"/>
          <StyledSpan>70% are unhappy at work</StyledSpan>
        </StyledHappinessCol>
        <StyledHappinessCol xs={{span: 6, offset:3}} md={{span: 2, offset:0}}>
          <StyledImage className="img-responsive" src="/images/happy2.png"/>
          <StyledSpan>50% are not satisfied with the current job</StyledSpan>
        </StyledHappinessCol>
        <StyledHappinessCol xs={{span: 6, offset:3}} md={{span: 2, offset:0}}>
          <StyledImage className="img-responsive" src="/images/happy3.png"/>
          <StyledSpan>80% find their work stressful</StyledSpan>
        </StyledHappinessCol>
        <StyledHappinessCol xs={{span: 6, offset:3}} md={{span: 2, offset:0}}>
          <StyledImage className="img-responsive" src="/images/happy4.png"/>
          <StyledSpan>75% do not have good friends at work</StyledSpan>
        </StyledHappinessCol>
        <StyledHappinessCol xs={{span: 6, offset:3}} md={{span: 2, offset:0}}>
          <StyledImage className="img-responsive" src="/images/happy5.png"/>
          <StyledSpan>65% do not like their bosses</StyledSpan>
        </StyledHappinessCol>
      </Row>

      <p>&nbsp;</p>
      <Content>
        <p>The Happiness Program is a well designed program to increase the Happiness
        Index of your workplace. The program is for 3 months with educative and entertaining
        activities every fortnight to enable the participants to gradually move towards a happier self.
        The program addresses the factors which cause stress at work and help participants
        align with company values, bond with colleagues and team, resolve conflicts,
        communicate better, and cultivate a positive outlook.</p>
      </Content>

      <Heading>
        <Title>THP Plan</Title>
      </Heading>

      <Row>
        <StyledCol sm={12} md={6}>
          <ServiceTitle>Pre-Start</ServiceTitle>
          <ServiceContent>
            <p>Measure happiness on various parameters as well as Happiness Index.
            Getting a detailed profile of participants as per our format.</p>
          </ServiceContent>
        </StyledCol>
        <StyledCol sm={12} md={6}>
          <ServiceTitle>Week-1</ServiceTitle>
          <ServiceContent>
            <p>A talk on values of the company, storytelling, inculcating Humor and
            Happiness in life and understanding the group and their dynamics.</p>
          </ServiceContent>
        </StyledCol>
      </Row>
      <Row>
        <StyledCol sm={12} md={6}>
          <ServiceTitle>Week-3</ServiceTitle>
          <ServiceContent>
            <p>Participants tell their true work stories in a Town Hall gathering
            with all employees, picking one company value as a central theme and
            hence also validating for others that the company lives by it’s values.
            Everyone is encouraged to speak.</p>
          </ServiceContent>
        </StyledCol>
        <StyledCol sm={12} md={6}>
          <ServiceTitle>Week-5</ServiceTitle>
          <ServiceContent>
            <p>&nbsp;</p>
            <p>A creative workshop on humor.
            Adding humor to work, life, and stories.</p>
          </ServiceContent>
        </StyledCol>
      </Row>
      <Row>
        <StyledCol sm={12} md={6}>
          <ServiceTitle>Week-7</ServiceTitle>
          <ServiceContent>
            <p>Participants tell funny stories out of their lives and make it personal
            in front of all employees. Everyone else  is also encouraged to participate.</p>
          </ServiceContent>
        </StyledCol>
        <StyledCol sm={12} md={6}>
          <ServiceTitle>Week-9</ServiceTitle>
          <ServiceContent>
            <p>A discussion on how to approach conflict situations and how humor can help.
            Team Bonding IMPROV exercises are also done.</p>
          </ServiceContent>
        </StyledCol>
      </Row>
      <Row>
        <StyledCol sm={12} md={6}>
          <ServiceTitle>Week-11</ServiceTitle>
          <ServiceContent>
            <p>3 select participants present PPTs and solutions on topics
            (pre-selected by the company) to all employees.
            The topics are relevant, relatable, and presented in a fun manner.</p>
          </ServiceContent>
        </StyledCol>
        <StyledCol sm={12} md={6}>
          <ServiceTitle>Way Ahead - Recommended</ServiceTitle>
          <ServiceContent>
            <p>An ongoing fortnightly fun and happiness activities are initiated
            and executed by the company and the people trained are designated
            ‘Champions of Happiness’ at work.</p>
          </ServiceContent>
        </StyledCol>
      </Row>
    </Container>
)

export default Happiness
